import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Disconnect from '../components/Disconnect';
import AppDatas from '../data/AppDatas.json';
import '../style.css';


export default function Home(props) {
    const { user } = props;
    const [nebuchadnezzarRole, setNebuchadnezzarRole] = useState('');

    useEffect(() => {
        if (user && user.resource_access && user.resource_access.nebuchadnezzar) {

            const nebuchadnezzarRoles = JSON.stringify(user.resource_access.nebuchadnezzar.roles).slice(1,-1).replace(/"/g, '');
            if (nebuchadnezzarRoles) {
                setNebuchadnezzarRole(nebuchadnezzarRoles);
            }
        }
    }, [user]);

    if (!user || !user.preferred_username) {
        return <div>Chargement...</div>;
    }

    if (nebuchadnezzarRole) {
        return (

        <div className="home-container">
        <h1>{AppDatas.main.name}</h1>
                <h2>Nebuchadnezzar API</h2>
                <span>Informations de version pour la production Daisy Wheel v3</span>

                <div className="user-info">
                    <p>{user.preferred_username}</p>
                    {nebuchadnezzarRole && <p>{nebuchadnezzarRole}</p>}
                </div>

                <div style={{height: '38px'}}></div>

                <Link to="/interface" className="home-links">
                    Version
                </Link>
                <div style={{height: '38px'}}></div>
                <img src="/zion.png" alt="Zion" style={{width: '333px', height: '220px'}}/>
                <div style={{height: '38px'}}></div>
                <Disconnect style={{color: 'lightcoral'}}/>
            </div>
        );
    }

    return (
        <div className="access-denied">
            <h1>Accès refusé</h1>
            <p>Vous n'avez pas accès à la page Nebuchadnezzar.</p>
            <Disconnect />
        </div>
    );
}
