import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    realm: 'master',
    url: 'https://auth.daisywheel.fr/auth/',
    clientId: 'nebuchadnezzar',
});

class AuthService {
    keycloak = keycloak;

    init() {
        return this.keycloak.init({ onLoad: 'login-required', checkLoginIframe: false });
    }

    getUserInfo() {
        return this.keycloak.tokenParsed;
    }
}

const authService = new AuthService();
export default authService;