import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import '../App.css';
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import dataServer from "../data/serverConf.json";
import authService from "../authService";

const baseUrl = dataServer.Server.protocol + '://' + dataServer.Server.IP + ':' + dataServer.Server.port;
Axios.defaults.withCredentials = true;

let generiqueMCJXList = [];
let persoMCJXList = [];
let generiqueMCJXOpts = [];
let persoMCJXOpts = [];
let options = [];

let state = {
    sn_glb: "",
    sn_cb: "",
    machineType: "generic",
    input_mcjx_select: "",
    GLB_user: ""
}

function Interface()
{

    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const [errMessage, setErrMessage] = useState("");
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [isMCJXChoosed, setIsMCJXChoosed] = useState(false);
    const [hasResult, setHasResult] = useState(false);
    const [result, setResult] = useState({});
    const [snGLBUsed, setSNGLBUsed] = useState('');
    const [snCBUsed, setSNCBUsed] = useState('');
    const [optionsList, setOptionsList] = useState([]);

    const init = () => {
        console.log("init ============== 1");


        Axios.get(`${baseUrl}/api/getCustomData`, {}).then((response) =>{
            console.log("init ============== 2");
            console.log(response);

            const resQuery = response.data.result;
            resQuery.map((line) => {
                options.push(line);
                switch(line.typeperso){
                    case 'generique':
                        generiqueMCJXList.push(line);
                        break;
                    case 'perso':
                        persoMCJXList.push(line);
                        break;
                    default:
                        console.log(`Error in line with codemcjx: ${line.code_mcjx}`);
                        break;
                }
                return 'New Line Created'
            });
            /*console.log(options);
            console.log(generiqueMCJXList);
            console.log(persoMCJXList);*/

            generiqueMCJXOpts.push(<option key={'baseOptionGen'} value={''}>Choisir une template générique</option>);
            persoMCJXOpts.push(<option key={'baseOptionPer'} value={''}>Choisir une template personnalisée</option>);
            /*generiqueMCJXOpts.push(<option key={'baseOption'} value={''} disabled>- Choisir une template -</option>);
            persoMCJXOpts.push(<option key={'baseOption'} value={''} disabled>- Choisir une template -</option>);*/

            let mcjxCode_prefix = '';
            generiqueMCJXList.map((option, key) => {
                mcjxCode_prefix = switchMcjxCodePrefix(option.code_mcjx, 'generique');
                return generiqueMCJXOpts.push(<option key={`genmcjxopts${key}`} value={option.code_mcjx}>{mcjxCode_prefix}{option.code_mcjx} - {option.ligne}</option>);
            })
            persoMCJXList.map((option, key) => {
                mcjxCode_prefix = switchMcjxCodePrefix(option.code_mcjx, 'custom');
                return persoMCJXOpts.push(<option key={`permcjxopts${key}`} value={option.code_mcjx}>{mcjxCode_prefix}{option.code_mcjx} - {option.ligne}</option>);
            })
            setOptionsList(generiqueMCJXOpts);
        });
    };

    useEffect(init, []);

    function switchMcjxCodePrefix(code_mcjx, type) {
        switch(type){
            case 'generique':
                if(code_mcjx.length === 2){
                    return 'MCJx19';
                }else if(code_mcjx.length > 2){
                    return '';
                }else{
                    return '';
                }
                break;

            case 'custom':
                if(code_mcjx.length === 2){
                    return 'MCJx10';
                }else if(code_mcjx.length > 2){
                    return '';
                }else{
                    return '';
                }
                break;

            default:
                return '';
                break;
        }
    }

    const handleInputText = (event) => {
        event.preventDefault();
        setErrMessage("");
        setHasResult(false);
        const value = event.target.value;
        state[event.target.name] = value;
        testInputs();
    }

    const handleFieldSet = (event) => {
        setErrMessage("");
        setHasResult(false);
        const value = event.target.value;
        state[event.target.name] = value;
        state.machineType = value;
        updateOptions();
        var selectMCJX = document.getElementById('input-mcjx');
        selectMCJX.selectedIndex = 0;
        setIsMCJXChoosed(false);
        testInputs();
    }

    const handleSelect = (event) => {
        event.preventDefault();
        setErrMessage("");
        setHasResult(false);
        const value = event.target.value;
        if(value != null){
            state[event.target.name] = value;
            testInputs();
            setIsMCJXChoosed(true);
        }else{
            setIsMCJXChoosed(false);
        }
    }

    const updateOptions = () => {
        let temp = [];
        if(state.machineType === 'generic'){
            temp = generiqueMCJXOpts;
            state.input_mcjx_select = '';
        }else if(state.machineType === 'custom'){
            temp = persoMCJXOpts;
            state.input_mcjx_select = '';
        }else{
            setErrMessage("Choix erroné, impossible de proposer les options !");
            state.input_mcjx_select = '';
        }
        setOptionsList(temp);
    }

    const testInputs = () => {
        const temp_SNG = state.sn_glb;
        const temp_SNC = state.sn_cb;
        const temp_MCJX = state.input_mcjx_select;

        if(testSN(temp_SNG) &&
            testSN(temp_SNC) &&
            testMCJX(temp_MCJX)
        ){
            setIsButtonVisible(true);
        }else{
            setIsButtonVisible(false);
        }
    }

    function testSN(sn) {
        let isNum = /^\d+$/.test(sn);
        if(sn.length === 6 &&
            isNum
        ){
            return true;
        }else{
            return false;
        }
    }

    function testMCJX(MCJX) {
        let isNum = /^\d+$/.test(MCJX);
        if(MCJX !== '' &&
            isNum &&
            MCJX.length === 2
        ){
            return true;
        }else{
            return false;
        }
    }

    const search = (event) => {
        event.preventDefault();
        const temp_SNG = 'SN1000' + state.sn_glb;
        const temp_SNC = 'SN1000' + state.sn_cb;
        const temp_MCJX = state.input_mcjx_select;
        const temp_user = state.GLB_user;
        if(temp_SNG !== temp_SNC){
            Axios.get(`${baseUrl}/api/searchData`, {params: {sn_glb: temp_SNG, sn_cb: temp_SNC, code_mcjx: temp_MCJX, user: temp_user}}).then((response) => {
                if(response.data.error === false){
                    const resQuery = response.data.result[0];
                    setResult({
                        "dwwiVersion": resQuery.dwwi_version,
                        "pipVersion": resQuery.pip_version,
                        "elecVersion": resQuery.elec_version,
                        "canisterVersion": resQuery.canister_version,
                        "paintVersion": resQuery.paint_version,
                        "dwMajVersion": resQuery.DWmaj_version
                    })
                    setHasResult(true);
                    setSNGLBUsed(`SN Global: ${state.sn_glb}`);
                    setSNCBUsed(`SN CentralBox: ${state.sn_cb}`);

                    const inputSNGLB = document.getElementById('input_sn_global');
                    const inputSNCB = document.getElementById('input_sn_centralbox');
                    inputSNGLB.value = '';
                    inputSNCB.value = '';
                    state.sn_glb = "";
                    state.sn_cb = "";
                }else{
                    console.log("Erreur pendant la récupération des données !");
                    setHasResult(false);
                }
            });
        }else{
            setErrMessage("Les SN sont équivalents !");
            setHasResult(false);
        }
    };

    const funcDisconnect = () => {
            navigate('/', { replace: true });
    };

    return (
    <>
        <div className="App">

        <button id="button-disconnect" className="button-disconnect" onClick={funcDisconnect}>
                    <img src="/home.png" alt="Home" style={{width: '156px', height: '156px'}}/>
                </button>
                <div style={{height: '38px'}}></div>

                <h1 className={'main-title'}>Interface</h1>
                <div className={"search-tools"}>
                    <div id={"search-form"}>
                        <section>
                            <label htmlFor={'input_sn_global'}>SN_Global</label>
                            <input type={"text"} onChange={handleInputText} id={'input_sn_global'} name={'sn_glb'}
                                   maxLength={'6'} placeholder={'112233'}/>
                        </section>
                        <section>
                            <label htmlFor={'input_sn_centralbox'}>SN_Centralbox</label>
                            <input type={"text"} onChange={handleInputText} id={'input_sn_centralbox'} name={'sn_cb'}
                                   maxLength={'6'} placeholder={'112233'}/>
                        </section>
                        <section id={"type-select"}>
                            <fieldset onChange={handleFieldSet}>
                                <legend>Sélectionner un type:</legend>
                                <div>
                                    <input type="radio" id="generic" name="machineType" value="generic" defaultChecked/>
                                    <label htmlFor="generic">Générique</label>
                                </div>
                                <div>
                                    <input type="radio" id="custom" name="machineType" value="custom"/>
                                    <label htmlFor="custom">Personnalisée</label>
                                </div>
                            </fieldset>
                        </section>
                        <section id={"mcjx-section"}>
                            <label htmlFor={'input-mcjx'}>Code MCJX</label>
                            <select name={'input_mcjx_select'} id={'input-mcjx'} onChange={handleSelect}>
                                {optionsList}
                            </select>
                        </section>
                    </div>
                </div>
                {isButtonVisible && isMCJXChoosed ?
                    <button onClick={search} id={'searchQRCode-button'}>🔍</button>
                    : null
                }
                <h3>{errMessage}</h3>
                <div className={'display-result'}>
                    {hasResult ?
                        <>
                            <fieldset>
                                <legend>Version logiciel</legend>
                                <span>DWWI Version: {result.dwwiVersion}</span>
                                <br/>
                                <span>PIP Version : {result.pipVersion}</span>
                                <br/>
                                <span>Version de la carte électronique : {result.elecVersion}</span>
                            </fieldset>
                            <fieldset>
                                <legend>Paint configuration</legend>
                                <span>Version du Canister : {result.canisterVersion}</span>
                                <br/>
                                <span>Version du Paints : {result.paintVersion}</span>
                            </fieldset>
                            <fieldset>
                                <legend>Mise a jour</legend>
                                <span>Version de la Mise à jour : {result.dwMajVersion}</span>
                            </fieldset>
                            <fieldset>
                                <legend>SN Utilisés</legend>
                                <span>{snGLBUsed}</span>
                                <br/>
                                <span>{snCBUsed}</span>
                            </fieldset>
                        </>
                        : <></>}
                </div>
            </div>
        </>
    );
}

export default Interface;
