import React from 'react';
import authService from '../authService';
import { useNavigate } from 'react-router-dom';

export default function Disconnect() {
  const navigate = useNavigate();

  const disconnect = () => {
    authService.keycloak.logout();
    navigate('/login', { replace: true });
  };

  return <a className={'home-links'} onClick={disconnect}>Déconnexion</a>;
}