import React, { useState, useEffect } from 'react';
import authService from './authService';
import { Route, Routes } from 'react-router-dom';
import Login from "./pages/Login";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak-config';
import Home from "./pages/Home";
import Interface from "./pages/Interface";
import './App.css';

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        authService.init().then((authenticated) => {
            if (authenticated) {
                setUser(authService.getUserInfo());
            }
        });
    }, []);

    return (
        <ReactKeycloakProvider authClient={keycloak}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/home" element={<Home user={user} />} />
                <Route path="/interface" element={<Interface />} />
            </Routes>
        </ReactKeycloakProvider>
    );
}

export default App;
